export const getCheckoutSession = async (product: string, giftEmail?: string) => {
  const res = await fetch(`/api/checkout_sessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ product, giftEmail }),
    credentials: 'include'
  });

  const data = await res.json();

  return data as { url: string };
};
